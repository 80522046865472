<template>
    <div
        class="agent-phone-control-wrap"
        ref="wrap"
        :class="{
            'agent-phone-control-wrap--fullscreen': modalWindowFullscreen,
        }"
    >
        <!-- Звонок только пришел -->
        <ShiftLayoutAppearAnimation
            :visible="
                newCall && !conferenceCall && !isCalling && !isViewClientPage
            "
            height="88"
        >
            <div>
                <div class="agent-phone-control">
                    <div class="d-flex align-items-center">
                        <div
                            class="agent-phone-control__item agent-phone-control__item--large"
                        >
                            <ProblemDescriptionItem
                                image="call/call-hello-001.svg"
                                :name="'components.common.talkToMe'"
                                :value="
                                    call.phone ||
                                    $t('components.common.unknownNumber')
                                "
                                type="phone"
                                theme="main"
                            />
                        </div>
                        <div class="agent-phone-control__item">
                            <ProblemDescriptionItem
                                icon="globe"
                                name="components.common.serviceLanguage"
                                :value="
                                    call.language
                                        ? $t(
                                              'common.languages.' +
                                                  call.language
                                          )
                                        : '-'
                                "
                            />
                        </div>
                        <div class="agent-phone-control__item">
                            <ProblemDescriptionItem
                                icon="control-phone"
                                name="components.common.calTo"
                                :value="phoneInfo"
                            />
                        </div>
                    </div>
                    <div class="agent-phone-control__actions">
                        <button
                            v-tooltip="$t('components.common.answerCall')"
                            class="call-button-a"
                            @click="answerCall"
                        >
                            <AppIcon icon="phone" />
                            {{ formattedAnswerCallTime }}
                        </button>
                    </div>
                </div>
            </div>
        </ShiftLayoutAppearAnimation>

        <!-- Звонок активен -->
        <ShiftLayoutAppearAnimation
            :visible="
                newCall && !conferenceCall && isCalling && !isViewClientPage
            "
            height="88"
        >
            <div>
                <div
                    class="agent-phone-control"
                    :class="{
                        ['agent-phone-control--theme-' + callTheme]: callTheme,
                    }"
                >
                    <transition name="agent-indicator" mode="out-in">
                        <div
                            class="agent-phone-control__indicator"
                            :key="callTheme"
                        />
                    </transition>
                    <transition name="agent-info" mode="out-in">
                        <div
                            :key="clientInfoKey"
                            class="d-flex align-items-center"
                        >
                            <div
                                class="agent-phone-control__item agent-phone-control__item--large"
                            >
                                <ProblemDescriptionItem
                                    icon="user"
                                    :class="{
                                        'user-tags-shaking': usernameChanged,
                                    }"
                                    name=""
                                    :value="
                                        call.phone ||
                                        $t('components.common.unknownNumber')
                                    "
                                    type="user-phone"
                                >
                                    <template #name>
                                        <BankUserTags
                                            :value="clientName"
                                            :user-data="userData"
                                        />
                                    </template>
                                    <template #icon-additional>
                                        <div
                                            class="lang-icon clickable-item"
                                            @click="changeLanguage"
                                        >
                                            <img :src="iconLang" alt="flag" />
                                        </div>
                                    </template>
                                </ProblemDescriptionItem>
                            </div>
                            <div class="agent-phone-control__item">
                                <ProblemDescriptionItem
                                    icon="folder-open"
                                    name="components.common.clientCategory"
                                    :theme="
                                        fieldChanged('client_type')
                                            ? 'success'
                                            : ''
                                    "
                                    :value="
                                        getClientCategory(userData.client_type)
                                    "
                                />
                            </div>
                            <div class="agent-phone-control__item">
                                <ProblemDescriptionItem
                                    icon="code"
                                    name="components.common.clientCode"
                                    :class="{
                                        'user-tags-shaking':
                                            fieldChanged('code'),
                                    }"
                                    :theme="
                                        fieldChanged('code')
                                            ? 'warning-icon'
                                            : ''
                                    "
                                    :value="userData.code || '-'"
                                />
                            </div>
                            <div class="agent-phone-control__item">
                                <ProblemDescriptionItem
                                    icon="control-phone"
                                    name="components.common.calTo"
                                    :value="phoneInfo"
                                />
                            </div>
                        </div>
                    </transition>
                    <transition name="agent-buttons" mode="out-in">
                        <div
                            :key="clientButtonsKey"
                            class="agent-phone-control__actions"
                        >
                            <div class="block-wrap">
                                <template v-if="clientCallFinished">
                                    <div v-if="isClientDropped">
                                        <ProblemDescriptionItem
                                            icon="user-cancel"
                                            name="components.common.theClientHungUp"
                                            :value="consultFinishTimerText"
                                            theme="full-danger"
                                        />
                                    </div>
                                    <div
                                        v-else-if="isOvertime"
                                        key="over-1"
                                        class="d-flex align-items-center"
                                    >
                                        <ProblemDescriptionItem
                                            class="text-right mr-18"
                                            name="components.common.downtime"
                                            :value="overtimeTimerText"
                                            theme="overtime"
                                        />
                                        <button
                                            v-tooltip="
                                                $t(
                                                    'components.common.closePanel'
                                                )
                                            "
                                            class="close-btn close-btn--theme-overtime"
                                            @click="finishCall"
                                        >
                                            <AppIcon icon="timer" />
                                        </button>
                                    </div>
                                    <div
                                        v-else
                                        key="finish-1"
                                        class="d-flex align-items-center"
                                    >
                                        <ProblemDescriptionItem
                                            class="mr-42"
                                            icon="timer"
                                            name="components.common.beforeClosing"
                                            :value="finishTimerText"
                                            theme="full-danger"
                                        />
                                        <button
                                            v-tooltip="
                                                $t(
                                                    'components.common.closePanel'
                                                )
                                            "
                                            class="close-btn"
                                            @click="finishCall"
                                        >
                                            <AppIcon icon="phone-hangup" />
                                        </button>
                                    </div>
                                </template>
                                <template v-else>
                                    <button
                                        v-tooltip="
                                            () =>
                                                $t(
                                                    'components.common.resetCall'
                                                )
                                        "
                                        class="call-button-a call-button-a--theme-red"
                                        @click="dropCall"
                                    >
                                        <AppIcon icon="hang" />
                                        {{ formattedDropCallTime }}
                                    </button>
                                    <div>
                                        <div
                                            class="button-double"
                                            :class="{
                                                'button-double--active':
                                                    ispause,
                                            }"
                                            @click="onCallPause"
                                        >
                                            <div class="button-double__front">
                                                <button
                                                    v-tooltip="
                                                        $t(
                                                            'components.common.continueCall'
                                                        )
                                                    "
                                                    :tabindex="
                                                        ispause ? null : '-1'
                                                    "
                                                    :disabled="!ispause"
                                                    class="call-button-a call-button-a--theme-yellow"
                                                >
                                                    <AppIcon icon="stop" />
                                                    {{ formattedPauseCallTime }}
                                                </button>
                                            </div>
                                            <div class="button-double__back">
                                                <AppButton
                                                    v-tooltip="
                                                        $t(
                                                            'components.common.holdCall'
                                                        )
                                                    "
                                                    :disabled="ispause"
                                                    theme="white-outline"
                                                    round
                                                >
                                                    <AppIcon icon="stop" />
                                                </AppButton>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="!isIncomingCall">
                                        <AppButton
                                            v-tooltip="
                                                $t('components.common.callBack')
                                            "
                                            :disabled="ispause"
                                            theme="primary-outline"
                                            round
                                            @click="toggleRecallsModal"
                                        >
                                            <AppIcon icon="calendar-event-2" />
                                        </AppButton>
                                    </div>

                                    <div
                                        v-if="!isConsultCall"
                                        class="agent-phone-control__dialer-button"
                                        v-clickaway="closeDropdown"
                                    >
                                        <AppButton
                                            v-tooltip="
                                                $t(
                                                    'components.common.consultationCall'
                                                )
                                            "
                                            theme="white-outline"
                                            round
                                            @click="openDropdown"
                                        >
                                            <AppIcon icon="user-plus" />
                                        </AppButton>
                                        <transition name="dialer">
                                            <DialerWindow
                                                v-if="dropdown"
                                                class="agent-phone-control__dialer-window"
                                                body
                                                :button-text="
                                                    share == 2
                                                        ? 'components.common.callTransfer'
                                                        : 'components.common.call'
                                                "
                                                :phone-book="conferenceDialerPhoneBook"
                                                @submit="submitDialer"
                                                @phoneBookClose="togglePhoneBook"
                                            >
                                                <div
                                                    class="block-wrap justify-content-between align-items-center"
                                                    style="min-height: 64px;"
                                                >
                                                    <AvatarSwitcher
                                                        :value="share"
                                                        :items="[
                                                            {
                                                                key: 1,
                                                                icon: 'account-plus',
                                                            },
                                                            {
                                                                key: 2,
                                                                icon: 'share-call',
                                                            },
                                                        ]"
                                                        @input="
                                                            dialerTypeSwitcher
                                                        "
                                                    />
                                                    <AppButton
                                                        v-if="share === 2 && isPhoneBook"
                                                        v-tooltip="
                                                            $t(
                                                                'components.common.phoneBook'
                                                            )
                                                        "
                                                        theme="white-outline"
                                                        round
                                                        @click="togglePhoneBook"
                                                    >
                                                        <AppIcon icon="phone-book" />
                                                    </AppButton>
                                                    <transition name="opacity">
                                                        <div
                                                            v-if="
                                                                share == 1 &&
                                                                filialsList.length
                                                            "
                                                        >
                                                            <AppButton
                                                                round
                                                                size="medium"
                                                                theme="gray"
                                                                type="button"
                                                                v-tooltip="
                                                                    $t(
                                                                        'components.common.directoryOfBranches'
                                                                    )
                                                                "
                                                                @click="
                                                                    openFilialsList
                                                                "
                                                            >
                                                                <AppIcon
                                                                    icon="book-bookmark"
                                                                />
                                                            </AppButton>
                                                        </div>
                                                    </transition>
                                                </div>
                                            </DialerWindow>
                                        </transition>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </ShiftLayoutAppearAnimation>
        <!-- Звонок с консультантом -->
        <ShiftLayoutAppearAnimation
            :visible="show_connect_call && !conferenceCall"
            height="88"
        >
            <div>
                <div
                    class="agent-phone-control"
                    :class="{
                        ['agent-phone-control--theme-' + isPausedConsult
                            ? 'warning'
                            : null]: isPausedConsult ? 'warning' : null,
                    }"
                >
                    <transition name="agent-indicator" mode="out-in">
                        <div
                            class="agent-phone-control__indicator"
                            :key="isPausedConsult ? 'warning' : null"
                        />
                    </transition>

                    <div
                        class="agent-phone-control__item agent-phone-control__item--large"
                    >
                        <ProblemDescriptionItem
                            icon="user-voice"
                            :name="
                                consultCallName ||
                                'components.common.ourImportantSpecialist'
                            "
                            :value="consultCallNumber"
                            theme="danger"
                            type="user-phone"
                        >
                            <template #icon-additional>
                                <div class="lang-icon">
                                    <img
                                        src="../assets/img/alo-short-logo.svg"
                                        alt="flag"
                                    />
                                </div>
                            </template>
                        </ProblemDescriptionItem>
                    </div>
                    <div class="agent-phone-control__actions">
                        <transition
                            name="agent-buttons"
                            tag="div"
                            mode="out-in"
                        >
                            <div class="block-wrap" :key="isClientDropped">
                                <button
                                    v-tooltip="
                                        $t('components.common.resetCall')
                                    "
                                    class="call-button-a call-button-a--theme-red"
                                    @click="dropShareCall(false)"
                                >
                                    <AppIcon icon="hang" />
                                    {{ formattedConsultCallTime }}
                                </button>
                                <AppButton
                                    v-if="!isClientDropped"
                                    v-tooltip="
                                        $t('components.common.startAConference')
                                    "
                                    theme="primary"
                                    :disabled="isPausedConsult"
                                    round
                                    @click="initConferenceCall"
                                >
                                    <AppIcon icon="user-group" />
                                </AppButton>
                                <div>
                                    <div
                                        class="button-double"
                                        :class="{
                                            'button-double--active':
                                                isPausedConsult,
                                        }"
                                        @click="onConsultCallPause"
                                    >
                                        <div class="button-double__front">
                                            <button
                                                v-tooltip="
                                                    $t(
                                                        'components.common.continueCall'
                                                    )
                                                "
                                                :tabindex="
                                                    isPausedConsult
                                                        ? null
                                                        : '-1'
                                                "
                                                :disabled="!isPausedConsult"
                                                class="call-button-a call-button-a--theme-yellow"
                                            >
                                                <AppIcon icon="stop" />
                                                {{
                                                    formattedPausedConsultCallTime
                                                }}
                                            </button>
                                        </div>
                                        <div class="button-double__back">
                                            <AppButton
                                                v-tooltip="
                                                    $t(
                                                        'components.common.holdCall'
                                                    )
                                                "
                                                :disabled="isPausedConsult"
                                                theme="white-outline"
                                                round
                                            >
                                                <AppIcon icon="stop" />
                                            </AppButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </ShiftLayoutAppearAnimation>
        <!-- Конференция -->
        <ShiftLayoutAppearAnimation
            :visible="show_connect_call && conferenceCall"
            height="88"
        >
            <div>
                <div class="agent-phone-control">
                    <transition name="agent-info" mode="out-in">
                        <div
                            :key="clientConferenceDropped"
                            class="d-flex align-items-center"
                        >
                            <div
                                v-if="!clientConferenceDropped"
                                class="agent-phone-control__user agent-phone-control__item agent-phone-control__item--large"
                            >
                                <ProblemDescriptionItem
                                    icon="user"
                                    name=""
                                    :theme="
                                        fieldChanged('username')
                                            ? 'success'
                                            : ''
                                    "
                                    :class="{
                                        'user-tags-shaking':
                                            fieldChanged('username'),
                                    }"
                                    :value="
                                        call.phone ||
                                        $t('components.common.unknownNumber')
                                    "
                                    type="user-phone"
                                >
                                    <template #name>
                                        <BankUserTags
                                            :value="clientName"
                                            :user-data="userData"
                                        />
                                    </template>
                                    <template #icon-additional>
                                        <div
                                            class="lang-icon clickable-item"
                                            @click="changeLanguage"
                                        >
                                            <img :src="iconLang" alt="flag" />
                                        </div>
                                    </template>
                                </ProblemDescriptionItem>
                            </div>
                            <div
                                class="agent-phone-control__user agent-phone-control__item agent-phone-control__item--large"
                            >
                                <ProblemDescriptionItem
                                    icon="user-voice"
                                    :name="
                                        consultCallName ||
                                        'components.common.ourImportantSpecialist'
                                    "
                                    :value="consultCallNumber"
                                    theme="danger"
                                    type="user-phone"
                                >
                                    <template #icon-additional>
                                        <div class="lang-icon">
                                            <img
                                                src="../assets/img/alo-short-logo.svg"
                                                alt="flag"
                                            />
                                        </div>
                                    </template>
                                </ProblemDescriptionItem>
                            </div>
                        </div>
                    </transition>
                    <div class="agent-phone-control__actions">
                        <button
                            v-tooltip="$t('components.common.resetCall')"
                            class="call-button-a call-button-a--theme-red"
                            @click="dropCall"
                        >
                            <AppIcon icon="hang" />
                            {{ formattedDropCallTime }}
                        </button>
                    </div>
                </div>
            </div>
        </ShiftLayoutAppearAnimation>

        <ApplicationModal
            v-if="modalRecallsData.visible"
            v-bind="modalRecallsData"
            v-on="modalRecallsListeners"
        >
            <template #beginningOfForm>
                <div class="w-100 mb-4">
                    <div class="group-message__label">
                        {{ $t("common.selectDate") }}
                    </div>
                    <AppDatePicker
                        v-model="modalRecallsData.model.date"
                        :allow-clear="true"
                        ref="input"
                        size="large"
                        class="w-100"
                        default-value=""
                        placeholder="common.choose"
                    />
                </div>
                <div class="w-100 mb-4">
                    <div class="group-message__item">
                        <div class="group-message__label">
                            {{ $t("common.time") }}
                        </div>
                        <AppTimePicker
                            v-model="modalRecallsData.model.time"
                            class="otchet-time__field"
                            allow-clear
                            :placeholder="$t('components.common.timeWith')"
                            size="large"
                        />
                    </div>
                </div>
            </template>
        </ApplicationModal>
    </div>
</template>
<script>
import { intranetClient, post } from "../helpers/api";
import { mapMutations, mapState } from "vuex";
import {
    AGENT_STATES,
    AGENT_STATES_REASONCODES,
    CALL_STATES,
    CALL_ACTIONS,
    CALL_TYPES,
} from "@contact-center/asakabank-cisco-finesse";
import agentInstance from "../services/finesse-agent"
import { EventBus } from "../helpers/eventBus";
import CallVarParse from "../helpers/CallVarParse";
import clientCategories from "@/config/clientCategories";
import AppIcon from "./partials/AppIcon";
import AppButton from "./partials/AppButton";
import DialerWindow from "./partials/DialerWindow";
import AvatarSwitcher from "./layout/AvatarSwitcher";
import ProblemDescriptionItem from "./partials/ProblemDescriptionItem";
import TimerService from "../services/TimerService";
import ChatController, { CAN_CHAT } from "../services/ChatController";
import PushNotificationsService from "../services/PushNotificationsService";
import filterDate from "../mixins/FilterDate";
import {
    AGENT_CALL_STATES,
    OLD_STATUS_VALID_STATES,
    returnToOldStatusNotReadyCheck,
    returnToOldStatusWorkCheck,
} from "../config/call-states";
import BankUserTags from "./BankUserTags";
import clickaway from "../directives/clickaway";
import ShiftLayoutAppearAnimation from "./partials/animation/ShiftLayoutAppearAnimation";
import FullScreenModalMixin from "../mixins/FullScreenModalMixin";
import ABSCustomer from "../services/customer/ABSCustomer";
import { CallRoom } from "../services/chat/ChatModel";
import { getNextLang } from "../services/LangController";
import { getCustomerByPhone, useUpdateChat } from "../helpers/customerHelpers";
import { qqLang, ruLang, uzLang } from "../config/lang";
import AppInputA from "@/components/partials/AppInputA.vue";
import ApplicationModal from "@/components/modals/ApplicationModal.vue";
import { MODAL_RECALLS_DATA } from "@/data/components/AgentAndPhoneControl";
import AppDatePicker from "@/components/partials/AppDatePicker.vue";
import AppTimePicker from "@/components/partials/AppTimePicker.vue";

const answerCallTimer = new TimerService();
const dropCallTimer = new TimerService();
const pauseCallTimer = new TimerService();
const consultCallTimer = new TimerService();
const pausedConsultCallTimer = new TimerService();
const overtimeTimer = new TimerService();

const anonymUser = "Неизвестный клиент";

export default {
    name: "AgentAndPhoneControl",
    components: {
        AppTimePicker,
        AppDatePicker,
        ApplicationModal,
        AppInputA,
        ShiftLayoutAppearAnimation,
        BankUserTags,
        ProblemDescriptionItem,
        AvatarSwitcher,
        DialerWindow,
        AppButton,
        AppIcon,
    },
    directives: {
        clickaway,
    },
    mixins: [filterDate, FullScreenModalMixin],
    props: {
        isViewClientPage: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            show_connect_call: false,
            isShowMainCall: true,
            conferenceCall: false,
            share: 0,
            select: "",
            call: {
                phone: "",
                call_time: "",
                called_number: "1180",
                language: "ru",
                customer_type: "",
                isOut: false,
                task_id: null,
                isOutbound: false,
            },
            show_calls: false,
            dialog_id: 0,
            is_loading: false,
            is_held: false,
            is_answered: false,
            show_error: false,
            disconnect_error_counter: 30,
            ispause: false,
            isPausedConsult: false,
            dropdown: false,
            category_client: clientCategories,
            isConsultCall: false,
            consultDialogId: 0,
            consultCallNumber: "",
            isAnsweredByClick: false,
            IsUIUpdated: false,
            isConsultUIUpdated: false,

            isClientDropped: false,

            isFinished: false,
            finishTimerId: null,
            finishTimerValue: 0,
            isDropCall: false,

            overtimeTimer: overtimeTimer.getData(),

            consultCallName: "",

            answerCallTimer: answerCallTimer.getData(),
            dropCallTimer: dropCallTimer.getData(),
            pauseCallTimer: pauseCallTimer.getData(),
            consultCallTimer: consultCallTimer.getData(),
            pausedConsultCallTimer: pausedConsultCallTimer.getData(),

            consultFinishTimer: new TimerService(),

            pushNotifications: null,
            clientConferenceDropped: false,

            oldStatus: null,

            newIncomingCall: null,
            isDebug: process.env.NODE_ENV !== "production",
            modalRecallsData: new MODAL_RECALLS_DATA(),

            conferenceDialerPhoneBook: false,
        };
    },

    computed: {
        ...mapState("callData", ["callModel"]),
        ...mapState({
            status: (state) => state.status,
            show: (state) => state.step,
            newCall: (state) => state.call,
            isCalling: (state) => state.isCalling,
            callingNumber: (state) => state.callingNumber,
            whichNumber: (state) => state.whichNumber,
            operator: (state) => state.operator.operator,
            isIncomingCall: (state) => state.isIncomingCall,
            teamMessages: (state) => state.teamMessages.messages,
            isAnswered: (state) => state.isAnswered,
        }),
        ...mapState(["filialsList", "isChat"]),
        formattedAnswerCallTime: () =>
            answerCallTimer.getMinutesSecondsAndFormat(),
        formattedDropCallTime: () => dropCallTimer.getMinutesSecondsAndFormat(),
        formattedPauseCallTime: () =>
            pauseCallTimer.getMinutesSecondsAndFormat(),
        formattedConsultCallTime: () =>
            consultCallTimer.getMinutesSecondsAndFormat(),
        formattedPausedConsultCallTime: () =>
            pausedConsultCallTimer.getMinutesSecondsAndFormat(),
        overtimeTimerText: () => overtimeTimer.getMinutesSecondsAndFormat(),
        consultFinishTimerText() {
            return this.consultFinishTimer.getMinutesSecondsAndFormat();
        },

        userData() {
            const model = this.callModel;
            if (model) {
                return (
                    model.customers.find(
                        ({ id }) => id === model.selectedCustomer
                    ) || {}
                );
            }
            return {};
        },

        problemTouched() {
            try {
                const problemsActions =
                    this.callModel.problemsActions[
                        this.callModel.selectedCustomer
                    ];
                return problemsActions[problemsActions.length - 1];
            } catch (e) {
                return null;
            }
        },

        usernameChanged() {
            if (!this.callModel) return false;
            return (
                this.fieldChanged("username") ||
                this.fieldChanged("is_abs_active")
            );
        },

        iconLang() {
            switch (this.$store.state.callData.callModel.lang) {
                case "ru":
                    return ruLang;
                case "uz":
                    return uzLang;
                case "qr":
                    return qqLang;
                case "qq":
                    return qqLang;
            }
        },
        isOvertime: {
            get() {
                return this.$store.state.isCallOvertime;
            },
            set(val) {
                this.$store.commit("setIsCallOvertime", val);
            },
        },

        callTheme() {
            if (this.isClientDropped) return "danger";
            else if (this.isOvertime) return "overtime";
            else if (this.ispause) return "warning";
            else if (this.isFinished) return "danger";
            else if (this.userIdentified) return "success";
        },
        clientButtonsKey() {
            return (
                "" +
                +this.isConsultCall +
                +this.isFinished +
                +this.isOvertime +
                +this.isClientDropped
            );
        },
        clientInfoKey() {
            return (
                this.userData.username +
                this.userData.client_type +
                this.userData.code
            );
        },
        consultButtonsKey() {
            return this.isClientDropped;
        },
        clientName() {
            return this.isShowMainCall && this.userData.username
                ? this.userData.username
                : anonymUser;
        },
        clientCallFinished() {
            return this.isOvertime || this.isClientDropped || this.isFinished;
        },

        userIdentified() {
            return !!this.userData.bank_client_id;
        },

        finishTimerText() {
            const minutes = Math.floor(this.finishTimerValue / 60);
            const seconds = Math.round(this.finishTimerValue % 60);
            return `${minutes < 10 ? "0" : ""}${minutes} : ${
                seconds < 10 ? "0" : ""
            }${seconds}`;
        },
        showStatusClass() {
            return this.show_calls ? "d-none" : "d-inline";
        },
        showHeldButtonClass() {
            return this.is_held ? "d-none" : "d-inline";
        },
        showRetrieveButtonClass() {
            return this.is_held ? "d-inline" : "d-none";
        },
        agentCallStatus() {
            return this.$store.state.callData.operatorState;
        },
        lang() {
            return this.$store.state.callData.callModel;
        },
        modalRecallsListeners() {
            return {
                reset: this.resetRecallForm,
                close: this.toggleRecallsModal,
                submit: this.submitRecall,
            };
        },
        phoneInfo() {
            if (!this.call.called_number) {
                return "-";
            }

            let message = this.call.called_number;

            if (this.call.customer_type) {
                message += " ( " + this.call.customer_type + " ) ";
            }

            return message;
        },
        isHomePage() {
            return this.$route.name === "ClientCard";
        },
        isPhoneBook() {
            if (process.env.VUE_APP_PHONE_BOOK_LIST) {
                return true
            }
            return false
        }
    },
    watch: {
        modalWindowFullscreen(value) {
            const wrap = this.$refs.wrap;
            if (value) {
                wrap.style.left = "0px";
                wrap.style.top = "-96px";
                wrap.style.width = "100vw";
                wrap.style.zIndex = 71;
                wrap.style.position = "fixed";
                setTimeout(() => {
                    requestAnimationFrame(() => {
                        wrap.style.transform = "translateY(88px)";
                    });
                }, 250);
            } else {
                wrap.style.transform = "";
                setTimeout(() => {
                    wrap.style = "";
                }, 250);
            }
        },
    },
    created() {
        console.log("created phone panel");
        this.initCiscoPhones();
        this.ispause = false;
        EventBus.$on("income-call-emitted", () => {
            this.imitateIncomeCall();
            if (!this.call) {
                answerCallTimer.startTimer();
            }
            this.$store.commit("getCall", true);
        });
        EventBus.$on("outgoing-call", (phone) => {
            if (this.isDebug) this.imitateOutgoingCall(phone);
            EventBus.$emit("make-call", { phone: phone.replace(/\s/g, "") });
            this.answerCall();
        });

        EventBus.$on("income-call", (callData) => {
            this.$store.commit("setOperatorPickedUpPhone", true);
            this.setAnswerCallOnKeyboardListener();
            answerCallTimer.startTimer();
            this.$store.commit("getCall", true);
            this.$store.commit("changeisIncomingCall", !this.call.isOutbound);
            this.$store.commit("callData/setCall", new CallRoom(callData));

            this.pushNotifications.createNotification();
        });
        EventBus.$on("switch-operator-status", (payload) => {
            this.sendProblemAction();
            this.clearStore();
            switch (payload.status) {
                case AGENT_CALL_STATES.READY:
                    this.agentReady();
                    break;
                case AGENT_CALL_STATES.TRAINING:
                    this.agentNotReady(
                        AGENT_STATES_REASONCODES.NOT_READY_TRAINING
                    );
                    break;
                case AGENT_CALL_STATES.PAUSE:
                    this.agentNotReady(
                        AGENT_STATES_REASONCODES.NOT_READY_WORKING_PAUSE
                    );
                    break;
                case AGENT_CALL_STATES.NOT_READY:
                    this.agentNotReady(AGENT_STATES_REASONCODES.NOT_READY);
                    break;
                case AGENT_CALL_STATES.POST_PROCESSING:
                    this.agentNotReady(+AGENT_CALL_STATES.POST_PROCESSING);
                    break;
                case AGENT_CALL_STATES.LUNCH:
                    this.agentNotReady(+AGENT_CALL_STATES.LUNCH);
                    break;
            }
        });

        EventBus.$on("make-call", ({ phone }) => {
            this.call.phone = phone;
            if (this.conferenceCall || this.isConsultCall) {
                this.$store.commit(
                    "notifications/error",
                    "Нельзя добавить больше двух участников"
                );
                return;
            }

            if (this.isCalling) {
                this.consultCall(phone);
            } else {
                this.makeCall(phone);
            }

            this.$store.commit("setOperatorPickedUpPhone", true);
        });

        EventBus.$on("send-team-message", (payload) => {
            const hours = parseInt(payload.time.slice(0, 2));
            const minutes = parseInt(payload.time.slice(3));
            const time = hours * 3600 + minutes * 60;

            agentInstance.finesse
                .SendTeamMessage(payload.message, payload.team, time.toString())
                .then(() => {
                    console.log("Send message");
                });
        });

        EventBus.$on("chat/answer-call", this.answerCall);
    },
    mounted() {
        this.pushNotifications = new PushNotificationsService(
            "Новый звонок",
            "Вам поступил новый звонок",
            this.answerCallOnPush
        );
    },
    beforeDestroy() {
        console.log("destroyed phone panel");
        EventBus.$off("income-call-emitted");
        EventBus.$off("income-call");
        EventBus.$off("switch-operator-status");
        EventBus.$off("make-call");
        EventBus.$off("chat/answer-call", this.answerCall);
        document.removeEventListener("click", this.close);
        this.closeFinishTimer();
        this.closeOvertimeTimer();
        this.clearStore();
    },
    methods: {
        ...mapMutations({
            switchApplicationStatusesType: "switchApplicationStatusesType",
        }),
        setAnswerCallOnKeyboardListener() {
            document.addEventListener("keydown", this.answerCallOnKeyboard);
        },

        togglePhoneBook() {
            this.conferenceDialerPhoneBook = !this.conferenceDialerPhoneBook
        },

        answerCallOnKeyboard(e) {
            if (!this.is_answered && e.code === "Enter") {
                if (this.isChat && this.$store.state.chat.incomingChat) {
                    return;
                }
                if (this.isChat) {
                    this.$store.commit("changeChatLayout", false);
                    setTimeout(
                        this.answerCall,
                        this.$store.state.chat.changeLayoutAnimationDelay
                    );
                } else {
                    this.answerCall();
                }
            }
        },
        clearAnswerCallOnKeyboardListener() {
            document.removeEventListener("keydown", this.answerCallOnKeyboard);
        },

        onConsultCallPause() {
            if (this.isPausedConsult) {
                this.retrieveConsultCall();
            } else {
                this.holdCallShareCall();
            }
        },
        onCallPause() {
            if (this.ispause) {
                this.retrieveCall();
            } else {
                this.holdCall();
            }
        },
        openFilialsList() {
            this.$store.commit("modals/openModal", "filialsList");
            this.dropdown = false;
        },
        makeCall(phone) {
            agentInstance.finesse.MakeCall(phone).then(() => {
                console.log("Make Call");
            });
        },
        dropPartipantCall() {
            if (!this.dialog_id) return;

            this.conferenceCall = false;
            agentInstance.finesse
                .DropCall(this.dialog_id, this.consultCallNumber)
                .then(() => {
                    console.log("Drop Call");
                });
        },
        startOvertimeTimer() {
            this.isOvertime = true;
            overtimeTimer.startTimer();
        },
        closeOvertimeTimer() {
            this.isOvertime = false;
            overtimeTimer.clearTimer();
        },
        startFinishTimer(cb, seconds) {
            this.isFinished = true;
            this.finishTimerValue = Math.round(seconds);

            this.finishTimerId = setInterval(() => {
                if (this.finishTimerValue <= 0) {
                    if (cb) {
                        cb();
                    }
                    this.closeFinishTimer();
                    return;
                }
                this.finishTimerValue--;
            }, 1000);
        },
        closeFinishTimer() {
            if (this.isFinished) this.isFinished = false;
            if (this.finishTimerId === null) return;

            clearInterval(this.finishTimerId);
            this.finishTimerId = null;
        },
        showCall(x) {
            this.show_calls = true;
            this.dialog_id = x.id;
            this.isShowMainCall = true;
            //Узбек тилида  or Русский
            if (x.callVariables.length) {
                const oCallVarParse = new CallVarParse(x.callVariables);
                this.call.called_number = oCallVarParse.getCallToPhone();

                if (!this.call.called_number) {
                    this.call.called_number =
                        process.env.VUE_APP_DEFAULT_PHONE_LINE;
                }
                this.call.language = oCallVarParse.getCallLanguage();
                this.call.customer_type = oCallVarParse.getCustomerType();

                if (this.call.customer_type === "ISHONCH") {
                    this.call.called_number = "Ishonch telefon"
                }
            }

            EventBus.$emit("income-call", this.call);
            this.$store.commit("callData/updateIncomingCall", this.call);
        },

        // ToDo Сделать глобальный метод для инциализации библиотеки FinesseAgent
        initCiscoPhones() {
            const that = this;

            agentInstance.xmpp.Connect().then((res) => {
                console.log("create connections");
                ChatController.initInstance(agentInstance);

                //subscribe to messages
                agentInstance.xmpp.messages$.subscribe({
                    next(x) {
                        if (!that.is_answered && !that.oldStatus) {
                            that.saveOldStatus();
                        }
                        if (
                            that.is_answered &&
                            !that.newIncomingCall &&
                            that.dialog_id &&
                            that.dialog_id !== x.id &&
                            (that.isFinished || that.isOvertime)
                        ) {
                            console.log("new call");
                            that.newIncomingCall = x.id;
                        }

                        if (
                            x.isOutCall &&
                            x.myInCallState === CALL_STATES.FAILED
                        ) {
                            that.$store.commit(
                                "notifications/error",
                                "Ошибка! Абонент вне зоны действия сети"
                            );

                            if (that.isConsultCall) {
                                that.dropShareCall(false);
                            } else {
                                that.dropCall();
                            }
                        } else if (
                            x.isOutCall &&
                            x.myInCallState !== CALL_STATES.DROPPED
                        ) {
                            if (
                                x.callType === CALL_TYPES.OUT &&
                                x.toAddress !== that.$store.state.auth.phone
                            ) {
                                if (that.is_answered) {
                                    const dialedPhones = that.fixPhoneNumber(
                                        x.toAddress
                                    );
                                    if (
                                        that.call.phone?.toString()?.length >
                                            0 &&
                                        that.call.phone !== dialedPhones
                                    ) {
                                        that.beforeConsultUpdateUI(
                                            dialedPhones
                                        );
                                        that.consultCallUpdateUI();
                                    }

                                    that.call.isOut = true;
                                    if (
                                        that.isFinished &&
                                        that.newIncomingCall
                                    ) {
                                        that.closeFinishTimer();
                                        that.wrapUpCallback();
                                    } else if (
                                        that.isOvertime &&
                                        that.newIncomingCall &&
                                        !that.isClientDropped
                                    ) {
                                        that.isClientDropped = true;
                                        that.consultFinishTimer.resetTimer();
                                    }
                                } else {
                                    that.show_calls = true;
                                    that.call.isOut = true;
                                    //исправляем баг с неправильно приклееным 0 в номере телефона
                                    if (x.toAddress) {
                                        that.call.phone = that.fixPhoneNumber(
                                            x.toAddress
                                        );
                                    }
                                    that.dialog_id = x.id;
                                    EventBus.$emit("income-call", that.call);
                                    that.$store.commit(
                                        "callData/updateIncomingCall",
                                        that.call
                                    );
                                    that.answerCallUpdateUI();
                                    that.requestCustomerData();
                                }
                            }
                        }
                        if (x.incominngCall && !that.is_answered) {
                            //исправляем баг с неправильно приклееным 0 в номере телефона
                            that.call.phone = that.fixPhoneNumber(
                                x.fromAddress
                            );
                            that.showCall(x);
                        }
                        if (x.id) {
                            console.log("my state " + x.myInCallState);
                            if (!that.dialog_id) {
                                that.dialog_id = x.id;
                                console.log("updating dialog id");
                            }
                        }
                        if (x.myInCallState == CALL_STATES.DROPPED) {
                            console.log("dropped");

                            const isOvertime = that.isOvertime,
                                newIncomingCall = that.newIncomingCall;

                            if (that.dialog_id == x.id) {
                                if (
                                    that.show_connect_call &&
                                    !that.isClientDropped &&
                                    !that.conferenceCall
                                ) {
                                    that.isClientDropped = true;
                                    that.consultFinishTimer.startTimer();
                                    return;
                                }
                                if (isOvertime) {
                                    return;
                                }

                                //drop consult call if exist
                                that.dropShareCall(false);
                                that.sendProblemAction();
                                that.clearStore();
                            } else {
                                that.call.isOut = false;
                                that.dropShareCall(false);
                            }
                            if (x.id === newIncomingCall) {
                                that.newIncomingCall = null;
                                if (isOvertime) {
                                    that.$store.commit(
                                        "callData/changeOperatorState",
                                        AGENT_CALL_STATES.OVERTIME
                                    );
                                    that.oldStatus = null;
                                    return;
                                } else {
                                    that.returnToOldStatus();
                                }
                            }
                            if (!that.newIncomingCall && !that.is_answered) {
                                that.returnToOldStatusWhenNoNACD();
                                that.oldStatus = null;
                            }
                        } else if (
                            that.agentCallStatus != AGENT_CALL_STATES.TALKING
                        ) {
                            // Звонок совершает оператор
                            if (x.isOutCall) {
                                that.$store.commit(
                                    "callData/changeOperatorState",
                                    AGENT_CALL_STATES.TALKING
                                );
                            } else if (x.callType == CALL_TYPES.OTHER_IN) {
                                let status = AGENT_CALL_STATES.RESERVED;
                                if (x.myInCallState == CALL_STATES.ACTIVE) {
                                    status = AGENT_CALL_STATES.TALKING;
                                }
                                that.$store.commit(
                                    "callData/changeOperatorState",
                                    status
                                );
                            }
                        }
                        if (x.myInCallState == CALL_STATES.HELD) {
                            if (that.dialog_id == x.id) {
                                //call from customer
                                if (!that.ispause) {
                                    that.holdCallUpdateUI();
                                }
                            } else {
                                //consult call
                                if (!that.isPausedConsult) {
                                    that.holdCallShareCallUI();
                                }
                            }
                        }
                        if (x.myInCallState == CALL_STATES.ACTIVE) {
                            if (that.dialog_id == x.id) {
                                if (x.callType === CALL_TYPES.OUTBOUND) {
                                    that.switchApplicationStatusesType(
                                        "outbound"
                                    );
                                } else {
                                    that.switchApplicationStatusesType(
                                        "default"
                                    );
                                }
                                //call from customer
                                if (that.ispause) {
                                    that.retrieveCallUpdateUI();
                                }
                                //call answered by phone or jabber
                                if (
                                    that.newCall &&
                                    !that.isCalling &&
                                    !that.isAnsweredByClick &&
                                    !that.call.isOut
                                ) {
                                    that.answerCallUpdateUI();
                                    that.requestCustomerData();
                                }
                                if (
                                    that.conferenceCall &&
                                    x.participantDroped
                                ) {
                                    if (x.dropedPhone == that.call.phone) {
                                        //show consult phone number
                                        that.clientConferenceDropped = true;
                                    } else {
                                        that.conferenceCall = false;

                                        that.show_connect_call = false;
                                        that.isConsultCall = false;
                                    }
                                }
                            } else {
                                //consult call
                                if (that.isPausedConsult) {
                                    that.retConsultCallUpdateUI();
                                }
                            }
                        }
                        if (
                            x.myInCallState === CALL_STATES.ALERTING &&
                            x.callType === "OUTBOUND"
                        ) {
                            const oCallVarParse = new CallVarParse(
                                x.callVariables
                            );
                            const taskID = oCallVarParse.getTaskID();
                            that.call.task_id = +taskID;
                            that.call.phone = that.fixPhoneNumber(
                                oCallVarParse.getCustomerNumberOnOutbound()
                            );
                            that.call.isOutbound = true;

                            that.showCall(x);
                        }
                    },
                    error(err) {
                        console.log("ERROR " + err);
                    },
                    complete() {
                        console.log("done");
                    },
                });

                //subscribe to errors
                const errorMessages = [
                    "CCX_AGENT_DEVICE_OFF",
                    "CCX_AGENT_DEVICE_BUSY",
                ];
                const ignoreErrors = ["Invalid State", "Generic Error"];
                agentInstance.xmpp.errors$.subscribe({
                    next(x) {
                        console.log(x);
                        if (ignoreErrors.includes(x.type)) {
                            return;
                        }
                        let message =
                            "Наташа, вставай, мы опять всё уронили 😿";
                        if (errorMessages.includes(x.message)) {
                            message = "Проверьте работоспособность телефона";
                        }
                        that.$store.commit("notifications/error", message);
                    },
                });

                //sign in
                agentInstance.finesse.SignIn().then((res) => {
                    //sign in handler
                    if (res.status === 202) {
                        //
                    }
                });

                //message about users
                agentInstance.xmpp.users$.subscribe({
                    next(x) {
                        if (x.loginId == that.$store.state.auth.username) {
                            console.log(x, "============");

                            if (
                                AGENT_STATES_REASONCODES.WORK_TIMER_EXP ==
                                x.reasonCode
                            ) {
                                EventBus.$emit("system-agent-lost-call", {
                                    isChat: false,
                                });
                            }

                            if (that.newIncomingCall) {
                                return;
                            }

                            let status = AGENT_CALL_STATES.READY;
                            if (
                                x.reasonCode ==
                                AGENT_STATES_REASONCODES.NOT_READY_TRAINING
                            ) {
                                status = AGENT_CALL_STATES.TRAINING;
                            } else if (
                                x.reasonCode ==
                                AGENT_STATES_REASONCODES.NOT_READY_WORKING_PAUSE
                            ) {
                                status = AGENT_CALL_STATES.PAUSE;
                            } else if (
                                x.reasonCode == AGENT_CALL_STATES.LUNCH
                            ) {
                                status = AGENT_CALL_STATES.LUNCH;
                            } else if (
                                x.reasonCode ==
                                AGENT_CALL_STATES.POST_PROCESSING
                            ) {
                                status = AGENT_CALL_STATES.POST_PROCESSING;
                            } else if (
                                x.reasonCode ==
                                AGENT_STATES_REASONCODES.NOT_READY_POST_CALL
                            ) {
                                status = AGENT_CALL_STATES.OVERTIME;
                            } else if (
                                x.reasonCode ==
                                    AGENT_STATES_REASONCODES.NOT_READY_NON_ACD_OUTBOUND_CALL ||
                                x.reasonCode ==
                                    AGENT_STATES_REASONCODES.NOT_READY_NON_ACD_INBOUND_CALL
                            ) {
                                // Телефония отправляет сообщения в данном случае, только если оператор в режиме готовности
                                // Пока ничего здесь не делаем, чтобы условие NOT_READY не сработало
                            } else if (x.state == AGENT_STATES.NOT_READY) {
                                status = AGENT_CALL_STATES.NOT_READY;
                            } else if (
                                x.state == AGENT_STATES.RESERVED_OUTBOUND ||
                                x.state ==
                                    AGENT_STATES.RESERVED_OUTBOUND_PREVIEW
                            ) {
                                status = AGENT_CALL_STATES.RESERVED;
                            } else if (x.state in AGENT_CALL_STATES) {
                                status = AGENT_CALL_STATES[x.state];
                            }
                            that.$store.commit(
                                "callData/changeOperatorState",
                                status
                            );

                            if (x.state == AGENT_STATES.WORK) {
                                if (that.isDropCall) {
                                    that.agentReady();
                                    that.isDropCall = false;
                                } else {
                                    if (that.conferenceCall) {
                                        that.conferenceCall = false;
                                        that.show_connect_call = false;
                                    }
                                    if (that.isClientDropped) {
                                        that.dropShareCall(false);
                                        that.wrapUpCallback();
                                        that.isClientDropped = false;
                                    } else {
                                        // Если стор уже очистили
                                        if (!that.is_answered) {
                                            return that.agentReady();
                                        }
                                        const wrapUp =
                                            x.wrapUpTimer > 5
                                                ? x.wrapUpTimer - 5
                                                : x.wrapUpTimer;
                                        that.startFinishTimer(() => {
                                            that.wrapUpCallback();
                                        }, wrapUp);
                                    }
                                }
                            }
                        }
                    },
                });

                //если разорвано соедение
                agentInstance.xmpp.connectionError$.subscribe({
                    next(x) {
                        if (x.code === "1") {
                            that.$store.commit("emitConnectionError");
                        }
                    },
                });

                agentInstance.xmpp.teamMessage$.subscribe({
                    next(x) {
                        that.$store.commit("putMessages", x);
                    },
                });
            });

            EventBus.$once("system-logout", () => {
                agentInstance.finesse.SignOut().then(() => {
                    agentInstance.xmpp.Disconect();
                });
            });

            agentInstance.finesse.GetServerTime().then((r) => {
                this.$store.commit("setServerTime", new Date(r).valueOf());
            });
            agentInstance.finesse.GetTeamMessages().then((messages) => {
                this.$store.commit("putMessages", messages);
            });
            agentInstance.finesse.GetUserTeams().then((messages) => {
                this.$store.commit("setUserTeams", messages);
            });

            EventBus.$on(
                "finesse.getTeam",
                (teamId, resolved, rejected = () => {}) => {
                    agentInstance.finesse
                        .GetTeam(teamId)
                        .then(resolved)
                        .catch(rejected);
                }
            );
        },
        wrapUpCallback() {
            if (this.problemTouched) {
                this.finishCall();
                return;
            }
            this.agentNotReady(AGENT_STATES_REASONCODES.NOT_READY_POST_CALL);
            this.startOvertimeTimer();
        },
        fixPhoneNumber(sPhoneNumber) {
            const phonePrefix = process.env.VUE_APP_CISCO_PHONE_PREFIX ?? "0";

            if (null !== sPhoneNumber.match(/^\+?998\d{9}/i)) {
                sPhoneNumber = sPhoneNumber.replace(/^\+?998/i, "");
            }
            if (
                phonePrefix === "0" &&
                null !== sPhoneNumber.match(/^(?!0)\d{5}0\d{4}$/i)
            ) {
                sPhoneNumber = sPhoneNumber.replace(
                    /^((?!0)\d{5})(0)(\d{4})$/i,
                    "$2$1$3"
                );
            }
            if (sPhoneNumber.length === 9) {
                sPhoneNumber = phonePrefix + sPhoneNumber;
            }

            return sPhoneNumber;
        },
        dialerTypeSwitcher(type) {
            this.share = type;

            if (this.share !== 2) {
                this.conferenceDialerPhoneBook = false
            }
        },
        initConferenceCall() {
            this.conferenceCall = true;
            if (this.dialog_id && this.consultCallNumber && agentInstance) {
                agentInstance.finesse
                    .MakeActionOnCall(
                        this.dialog_id,
                        this.consultCallNumber,
                        CALL_ACTIONS.CONFERENCE
                    )
                    .then((res) => {
                        console.log("IN CONFERENCE MODE");
                    });
            }
        },
        submitDialer(number) {
            if (number) {
                if (this.share == 1) {
                    this.consultCall(number);
                } else {
                    this.transferCall(number);
                }
            }
        },
        retConsultCallUpdateUI() {
            this.isPausedConsult = false;
            pausedConsultCallTimer.clearTimer();
            this.isConsultUIUpdated = true;
        },
        retrieveConsultCall() {
            this.retConsultCallUpdateUI();
            this.isPausedConsult = false;

            //hold consult call
            if (agentInstance) {
                agentInstance.finesse
                    .RetrieveConsultCall(this.consultCallNumber)
                    .then((res) => {
                        console.log("retrieve consult call");
                    });
            }
        },
        consultCall(dialedNumber) {
            if (this.ispause) {
                this.retrieveCall();
            }

            if (
                this.dialog_id &&
                dialedNumber &&
                agentInstance &&
                this.operator.phone !== dialedNumber
            ) {
                this.beforeConsultUpdateUI(dialedNumber);
                agentInstance.finesse
                    .MakeActionOnCall(
                        this.dialog_id,
                        dialedNumber,
                        CALL_ACTIONS.CONSULT_CALL
                    )
                    .then(() => {
                        console.log("CONSULT CALL STARTED");
                        this.consultCallUpdateUI();

                        intranetClient(
                            `/phonebook/?ordering=-order_by&page_size=50000&search=${dialedNumber}`
                        ).then((res) => {
                            if (res.data.results.length === 1) {
                                this.consultCallName =
                                    res.data.results[0].full_name;
                            }
                        });
                    });
            }
        },
        beforeConsultUpdateUI(dialedNumber) {
            this.isConsultCall = true;
            this.consultCallNumber = dialedNumber;
        },
        consultCallUpdateUI() {
            this.share = 0;
            this.show_connect_call = true;
            this.dropdown = false;

            consultCallTimer.startTimer();

            this.ispause = true;
            pauseCallTimer.startTimer();

            if (this.call.isOut) {
                this.holdCall();
            }
        },
        transferCall(phone) {
            if (this.ispause) {
                this.retrieveCall();
            }

            if (
                this.dialog_id &&
                agentInstance &&
                this.operator.phone !== phone
            ) {
                agentInstance.finesse
                    .MakeActionOnCall(
                        this.dialog_id,
                        phone,
                        CALL_ACTIONS.TRANSFER_SST
                    )
                    .then((result) => {
                        this.dropdown = false;
                        console.log("transfer call");
                    });
            }
        },
        holdCallShareCallUI() {
            this.isPausedConsult = true;
            pausedConsultCallTimer.startTimer();
        },
        holdCallShareCall() {
            this.holdCallShareCallUI();
            //hold consult call
            if (agentInstance) {
                agentInstance.finesse
                    .HoldConsultCall(this.consultCallNumber)
                    .then((res) => {
                        console.log("hold consult call");
                    });
            }
        },
        dropShareCall(bRetrieveMain = true) {
            this.$store.commit("callData/resetAppealFormData");
            pausedConsultCallTimer.clearTimer();
            consultCallTimer.clearTimer();
            this.isPausedConsult = false;
            this.$store.commit("setOperatorPickedUpPhone", false);

            if (this.conferenceCall) {
                return;
            }
            this.show_connect_call = false;
            this.consultCallName = "";

            this.share = 1;
            if (agentInstance) {
                agentInstance.finesse
                    .DropConsultCall(this.consultCallNumber)
                    .then(() => {
                        console.log("consult dropped");
                        this.isConsultCall = false;
                        if (this.isClientDropped) {
                            this.isClientDropped = false;
                            this.wrapUpCallback();
                            return;
                        }
                        if (bRetrieveMain) {
                            this.retrieveCall();
                        }
                    });
            }
        },
        getClientCategory(iCat = 0) {
            if (iCat) {
                const oCurCat = this.category_client.find((item) => {
                    return item.id === iCat;
                });
                return this.$t(oCurCat.name);
            }
            return "--";
        },
        agentReady() {
            //CHANGE AGENT STATE READY button
            if (agentInstance) {
                agentInstance.finesse
                    .ChangeState(AGENT_STATES.READY)
                    .then((res) => {
                        console.log(" Agent should be ready");
                    })
                    .catch((err) => {
                        this.$store.commit(
                            "notifications/error",
                            this.$t("errors.failedToChangeStatus")
                        );
                    });
            }
        },
        agentNotReady(iCode) {
            //CHANGE AGENT STATE NOT READY button
            if (agentInstance) {
                agentInstance.finesse
                    .ChangeState(AGENT_STATES.NOT_READY, iCode)
                    .then((res) => {
                        console.log(" AGent shoude be not ready");
                    })
                    .catch((err) => {
                        this.$store.commit(
                            "notifications/error",
                            this.$t("errors.failedToChangeStatus")
                        );
                    });
            }
        },
        /**
         * request customer data
         */
        requestCustomerData() {
            this.$emit("update-phone", this.call.phone);
            this.$store.commit("callData/updateIncomingCall", null);
            getCustomerByPhone(this.call.phone, this.callModel, false).finally(() =>
                this.$store.commit("getLoading", false)
            );
            if (this.dialog_id && agentInstance) {
                //product version
                this.is_answered = true;
            }
        },
        answerCallUpdateUI() {
            this.$store.commit("getLoading", true);
            answerCallTimer.clearTimer();
            dropCallTimer.startTimer();
            this.$store.commit("checkCalling", true);
        },
        answerCallOnPush() {
            if (this.isChat) {
                this.$store.commit("changeChatLayout", false);
                setTimeout(
                    this.answerCall,
                    this.$store.state.chat.changeLayoutAnimationDelay
                );
            } else {
                this.answerCall();
            }
        },
        answerCall() {
            this.isAnsweredByClick = true;
            this.$store.commit("setOperatorPickedUpPhone", true);
            this.clearAnswerCallOnKeyboardListener();
            this.answerCallUpdateUI();
            if (this.dialog_id && agentInstance) {
                agentInstance.finesse
                    .AnswerCall(this.dialog_id)
                    .then(this.requestCustomerData)
                    .catch(console.error);
            } else {
                this.requestCustomerData();
            }
        },
        saveOldStatus() {
            if (OLD_STATUS_VALID_STATES.includes(this.agentCallStatus)) {
                this.oldStatus = this.agentCallStatus;
            }
        },
        returnToOldStatusWhenNoNACD() {
            const isNotReady = returnToOldStatusNotReadyCheck(this.oldStatus);
            const work = returnToOldStatusWorkCheck(this.agentCallStatus);
            if (work && isNotReady) {
                // Звонок поступил не из очереди и мы вручную поменяли статусы
                this.$store.commit(
                    "callData/changeOperatorState",
                    this.oldStatus
                );
            } else if (work) {
                this.$store.commit(
                    "callData/changeOperatorState",
                    AGENT_CALL_STATES.READY
                );
            }
        },
        returnToOldStatus() {
            let status = this.oldStatus;
            switch (status) {
                case AGENT_CALL_STATES.NOT_READY:
                    this.agentNotReady(AGENT_STATES_REASONCODES.NOT_READY);
                    break;
                case AGENT_CALL_STATES.PAUSE:
                    this.agentNotReady(
                        AGENT_STATES_REASONCODES.NOT_READY_WORKING_PAUSE
                    );
                    break;
                case AGENT_CALL_STATES.TRAINING:
                    this.agentNotReady(
                        AGENT_STATES_REASONCODES.NOT_READY_TRAINING
                    );
                    break;
                case AGENT_CALL_STATES.LUNCH:
                    this.agentNotReady(AGENT_CALL_STATES.LUNCH);
                    break;
                case AGENT_CALL_STATES.POST_PROCESSING:
                    this.agentNotReady(AGENT_CALL_STATES.POST_PROCESSING);
                    break;
                default:
                    this.agentReady();
                    break;
            }
        },
        sendProblemAction() {
            if (this.problemTouched) {
                post("/end-call/", {
                    application_id: this.problemTouched,
                }).catch((e) => {
                    this.$store.commit(
                        "notifications/error",
                        "Информация о созданном обращении не сохранена"
                    );
                });
            }
        },
        finishCall() {
            if (!this.newIncomingCall) {
                this.returnToOldStatusWhenNoNACD();
                this.returnToOldStatus();
                this.oldStatus = null;
            }
            this.sendProblemAction();
            this.clearStore();
        },
        holdCallUpdateUI() {
            pauseCallTimer.startTimer();
            this.ispause = true;
        },
        holdCall() {
            this.holdCallUpdateUI();
            this.ispause = true;

            if (this.dialog_id && agentInstance) {
                agentInstance.finesse
                    .HoldCall(this.dialog_id)
                    .then((res) => {})
                    .catch((err) => {});
            }
        },
        retrieveCallUpdateUI() {
            pauseCallTimer.clearTimer();
            this.ispause = false;
            this.is_held = false;
            this.IsUIUpdated = true;
        },
        retrieveCall() {
            this.ispause = false;
            this.is_held = false;
            this.retrieveCallUpdateUI();

            if (this.dialog_id && agentInstance) {
                agentInstance.finesse
                    .RetrieveCall(this.dialog_id)
                    .then((res) => {
                        console.log("Agent retrieve call");
                        this.is_held = false;
                    })
                    .catch((err) => {});
            }
        },
        dropCall() {
            this.isDropCall = true;
            EventBus.$emit("apeal-details-modal-close");

            if (this.isDebug && this.dialog_id === 0) {
                this.$store.commit("getCall", false);
                this.$store.commit("setIsCallOvertime", false);
                this.$store.commit("checkCalling", false);
                this.$store.commit("callData/setCall", null);
                this.$store.commit("setOperatorPickedUpPhone", false);
                ChatController.off(CAN_CHAT, this.checkUserCanChat);
                EventBus.$off("call-ended");
                this.clearStore();
            }

            if (this.dialog_id && agentInstance) {
                agentInstance.finesse
                    .DropCall(this.dialog_id)
                    .then(() => {
                        this.$store.commit("setOperatorPickedUpPhone", false);
                        console.log("Agent drop call");
                    })
                    .finally(() => {
                        this.sendProblemAction();
                        this.clearStore();
                    });
                if (this.show_connect_call && !this.conferenceCall) {
                    this.dropShareCall(false);
                }
            }
        },
        clearStore() {
            ABSCustomer.clearData();
            this.show_calls = false;
            this.is_held = false;
            this.is_answered = false;
            this.call.phone = "";
            this.dialog_id = 0;
            this.consultDialogId = "";
            this.consultCallNumber = "";
            this.isConsultCall = false;
            this.conferenceCall = false;
            this.show_connect_call = false;
            this.isPausedConsult = false;
            this.call.isOut = false;
            this.isOvertime = false;

            this.$emit("clear");
            this.$store.commit("callData/resetAppealFormData");
            this.consultCallName = "";
            this.clearAnswerCallOnKeyboardListener();

            this.isDropCall = false;

            this.call = {
                phone: "",
                call_time: "",
                called_number: "1180",
                language: "ru",
                customer_type: "",
                isOut: false,
                task_id: null,
                isOutbound: false,
            };

            this.closeOvertimeTimer();
            this.closeFinishTimer();
            this.$store.commit("callData/updateIncomingCall", null);
            this.$store.commit("callData/setCall", null);

            this.clientConferenceDropped = false;
            this.ispause = false;
            this.isAnsweredByClick = false;
            this.IsUIUpdated = false;
            this.isConsultUIUpdated = false;
            this.isShowMainCall = true;
            this.isClientDropped = false;
            this.$store.commit("changeisIncomingCall", false);
            this.$store.commit("problems/clearNonEditableProblems");
            this.$store.commit("problems/clearProblems");
            this.$store.commit("getcard_stat", 1);

            this.consultFinishTimer.clearTimer();
            answerCallTimer.clearTimer();
            dropCallTimer.clearTimer();
            pauseCallTimer.clearTimer();
            this.$store.commit("checkCalling", false);
            this.$store.commit("getCall", false);
            this.$store.commit("clearCalcParams", false);
        },
        imitateCall() {
            this.$store.commit("checkCalling", true);
            EventBus.$emit("income-call", this.call);
            this.$store.commit("callData/updateIncomingCall", this.call);
        },
        imitateIncomeCall() {
            this.show_calls = true;
            this.call.phone = "0977929858";
            this.dialog_id = 0;
            this.call.language = "qr";
            this.call.customer_type = "type";
            EventBus.$emit("income-call", this.call);
            this.switchApplicationStatusesType("default");
            this.$store.commit("callData/updateIncomingCall", this.call);
            this.$store.commit("changeisIncomingCall", true);
        },
        closeDropdown() {
            this.dropdown = false;
        },
        openDropdown() {
            if (this.ispause) {
                return this.$emit("show-hold");
            }
            if (!this.dropdown) {
                this.dropdown = true;
                this.dialerTypeSwitcher(1);
            }
        },
        changeLanguage() {
            const model = this.$store.state.callData.callModel;
            const [data, update] = useUpdateChat(model);
            data.lang = getNextLang(model.lang);
            update();
        },
        fieldChanged(field) {
            if (!this.callModel) return false;
            return this.callModel.customerFieldsUpdated.includes(field);
        },
        imitateOutgoingCall(phone) {
            this.show_calls = true;
            this.call.phone = phone.replace(/\s/g, "");
            this.call.task_id = 46;
            this.dialog_id = 0;
            this.call.language = "ru";
            this.$store.commit("callData/updateIncomingCall", this.call);
            EventBus.$emit("income-call", this.call);
            this.switchApplicationStatusesType("outbound");
            this.$store.commit("changeisIncomingCall", false);
        },
        toggleRecallsModal() {
            this.modalRecallsData.visible = !this.modalRecallsData.visible;
        },
        resetRecallForm() {
            this.modalRecallsData = new MODAL_RECALLS_DATA();
        },
        submitRecall() {
            this.toggleRecallsModal();
        },
    },
};
</script>
<style lang="scss">
@import "../assets/scss/variables";

.agent-info,
.agent-buttons {
    &-enter-active,
    &-leave-active {
        transition: transform 0.2s, opacity 0.2s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}

.agent-info {
    &-enter,
    &-leave-to {
        transform: translateX(-48px);
    }
}

.agent-buttons {
    &-enter,
    &-leave-to {
        transform: translateX(48px);
    }
}

.agent-phone-control {
    $self: &;
    transition: border-top 0.25s, border-bottom 0.25s, box-shadow 0.25s;
    position: relative;
    z-index: 1;
    background: var(--color-white);
    box-shadow: 0px 4px 10px #dee7ff;
    border: 1px solid #f4f4f4;
    padding: 0 64px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;

    &__item {
        margin-right: 52px;

        &--large {
            min-width: 345 / 1808 * 100%;
            margin-right: 72px;
            max-width: 540px;
        }
    }

    &__actions {
        margin-left: auto;
    }

    &__simple-button {
        box-shadow: var(--shadow-button);
    }

    &__user {
        margin-right: 24px;
        width: 420px;
        height: 64px;
        display: flex;
        align-items: center;
        padding: 7px 15px;
        border: 1px solid var(--color-border);
        border-radius: 12px;
        background-color: var(--color-white);

        > * {
            max-width: 100%;
        }
    }

    &__dialer-button {
        position: relative;
    }

    &__dialer-window {
        z-index: 1;
        position: absolute;
        top: calc(100% + 1px);
        right: 0;
        box-shadow: var(--shadow-button);
    }

    &__indicator {
        transition: background 0.25s;
        position: absolute;
        bottom: -1px;
        left: -1px;
        right: -1px;
        height: 2px;
        background: transparent;
        border-radius: 12px;
    }

    &--theme-success,
    &.identified {
        #{$self}__indicator {
            background-color: var(--color-success);
        }
    }

    &--theme-danger,
    &.finished {
        #{$self}__indicator {
            background-color: var(--color-danger);
        }
    }

    &--theme-warning,
    &.yellow {
        #{$self}__indicator {
            background-color: var(--color-warning);
        }
    }

    &--theme-overtime {
        #{$self}__indicator {
            background-color: var(--color-overtime);
        }
    }
}

.agent-indicator {
    &-enter-active,
    &-leave-active {
        transition: transform 0.35s, background-color 0.35s;
    }

    &-enter,
    &-leave-to {
        transform: scaleX(0);
    }

    &-leave,
    &-enter-to {
        transform: scaleX(1);
    }
}

.agent-enter {
    &-enter-active,
    &-leave-active {
        transition: transform 0.25s, opacity 0.25s;
    }

    &-enter,
    &-leave-to {
        transform: translateY(-64px);
        opacity: 0;
    }
}

.button-double {
    $self: &;
    transition: width 0.25s;
    width: 48px;
    position: relative;

    &__front {
        display: flex;
        justify-content: flex-end;
        transition: opacity 0.25s;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        max-width: 100%;
    }

    &__back {
        position: absolute;
        right: 0;
        transition: opacity 0.25s !important;
        opacity: 1;
        pointer-events: auto;
        margin-left: auto;
    }

    &--active {
        width: 136px;

        #{$self}__front {
            opacity: 1;
            pointer-events: auto;
        }

        #{$self}__back {
            pointer-events: none;
            opacity: 0;
        }
    }

    &--chat {
        transition: box-shadow 0.25s, width 0.25s;
        border-radius: 50px;
        width: 44px;
        height: 44px;

        #{$self}__front {
            overflow: hidden;
            border-radius: inherit;

            > * {
                width: 100%;
            }
        }

        &#{$self}--focus {
            box-shadow: 0 0 0 3px var(--color-primary-lighter-04);
        }
    }

    &--chat#{$self}--active {
        width: 265px;
    }
}

@keyframes shaking {
    0% {
        transform: translateX(0);
    }
    8% {
        transform: translateX(10px);
    }
    16% {
        transform: translateX(-10px);
    }
    24% {
        transform: translateX(10px);
    }
    32% {
        transform: translateX(0);
    }
}

.user-tags-shaking {
    animation: shaking infinite 2s;
}

.agent-phone-control-wrap {
    transition: transform 0.25s;

    &--fullscreen {
        position: fixed;
        top: -96px;
        left: 0;
        right: 0;
        z-index: 71;
    }
}
</style>
